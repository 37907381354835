<template>
  <validation-provider
    v-slot="{ errors, validate }"
    ref="provider"
    :events="events"
    :name="fieldName"
    :rules="extendedRules"
    tag="div"
  >
    <v-checkbox
      v-model="modelValue"
      v-bind="$attrs"
      :error-messages="errors"
      :name="$attrs.name || fieldName"
      :required="required"
      color="purple-one darken-2"
      false-value="off"
      true-value="on"
      type="checkbox"
      persistent-hint
      @click="logOnClick($event, value)"
      @change="
        onChange(validate);
        logOnChange($event);
      "
    >
      <template #label>
        <slot name="label"/>
      </template>
    </v-checkbox>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwCheckbox',

  components: {
    'validation-provider': ValidationProvider,
  },

  inheritAttrs: false,

  props: {
    events: {
      type: [String, Array],
      default: 'change',
    },

    rules: {
      type: String,
      default: '',
    },

    // must be included in props
    value: {
      type: null,
      default: 'off',
      required: true,
    },
  },

  data: () => ({
    modelValue: '',
  }),

  computed: {
    ...mapGetters({
      apiErrors: 'application/apiErrors',
      initialData: 'application/getInitialData',
    }),

    extendedRules() {
      const delimiter = !this.rules ? '' : '|';
      const rules = this.rules.replace(/required/g, 'requiredCheckbox');
      return `${rules}${delimiter}error:${this.hasApiError}`;
    },

    fieldName() {
      return this.$attrs.id;
    },

    hasApiError() {
      return this.apiErrors.includes(this.fieldName);
    },

    initialValue() {
      return this.initialData[this.fieldName] || 'off';
    },

    preventSetData() {
      return Object.keys(this.$attrs).includes('prevent-set-data');
    },

    required() {
      return this.rules.split('|').includes('required');
    },
  },

  watch: {
    // Handles internal model changes.
    async modelValue(newVal) {
      await this.$nextTick();
      this.$emit('input', newVal);

      // use the submit method from the parent
      this.$emit('external-submit');
    },

    // Handles external model changes.
    value(newVal) {
      this.modelValue = newVal;
    },
  },

  created() {
    this.setInitialValue();
  },

  methods: {
    ...mapActions({
      removeApiError: 'application/removeApiError',
      setData: 'application/setData',
    }),

    getElementData(value) {
      return {
        targetId: this.fieldName,
        name: this.fieldName,
        nodeName: 'INPUT',
        type: 'checkbox',
        value: String(value),
      };
    },

    logOnChange(value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent({ type: 'change' }, data);
    },

    logOnClick(event, value) {
      const data = this.getElementData(value);

      this.$eventLogger.vuetifyEvent(event, data);
    },

    setInitialValue() {
      this.modelValue = this.initialValue;
    },

    async onChange(validate) {
      this.removeApiError(this.fieldName);

      await validate();

      if (this.modelValue !== 'on' && this.modelValue !== 'off') return;
      if (this.preventSetData) return;

      await this.submit();
    },

    async submit() {
      const params = {
        [this.fieldName]: this.modelValue,
      };
      await this.setData(params);
      this.$emit('group-change');
    },
  },
};
</script>
